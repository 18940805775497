// Color legend component for Scheduler

import './ColorLegend.scss';

export default function ColorLegend({ colorLegend }) {
  return (
    <ul className='color-legend'>
      {Object.entries(colorLegend).map(([divisionName, colorCode]) => (
        <li key={divisionName}>
          <div className='color-box' style={{ backgroundColor: colorCode }} />
          <p className='division-name'>
            {divisionName
              .replace(/,\s/, ',__PLACEHOLDER__')
              .replace(/ /g, '\u00A0')
              .replace(/,__PLACEHOLDER__/, ',\n')}
          </p>
        </li>
      ))}
    </ul>
  );
}
