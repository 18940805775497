// Encapsulates all the Firebase interaction logic
// Provides all the data and functions using context

import { useState, useEffect, useMemo, createContext, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { auth, db, getStorageUrl } from '../utils/firebase';
import { addThumbnailPostfix, getMatchMomentUTC } from '../utils/misc';
import moment from 'moment-timezone';
import { UsersRoles } from '../utils/enums';

const FirebaseContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);

export default function FirebaseContextProvider({ children }) {
  const [uid, setUid] = useState(null);

  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [referees, setReferees] = useState([]);
  const [payments, setPayments] = useState([]);

  const [isUsersLoaded, setIsUsersLoaded] = useState(false);
  const [isTeamsLoaded, setIsTeamsLoaded] = useState(false);
  const [isTournamentsLoaded, setIsTournamentsLoaded] = useState(false);
  const [isRefereesLoaded, setIsRefereesLoaded] = useState(false);
  const [isPaymentsLoaded, setIsPaymentsLoaded] = useState(false);

  const user = useMemo(
    () => users.find((user) => user.id === uid),
    [users, uid]
  );

  const snapToArr = (snapshot) => {
    let arr = [];
    snapshot.forEach((item) =>
      arr.push({
        id: item.id,
        ...item.data(),
      })
    );
    return arr;
  };

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        setIsUsersLoaded(false);
        setIsTeamsLoaded(false);
        setIsTournamentsLoaded(false);
        setIsPaymentsLoaded(false);
      } else {
        setUid(null);
        setUsers([]);
        setTeams([]);
        setTournaments([]);
        setPayments([]);
        setIsUsersLoaded(true);
        setIsTeamsLoaded(true);
        setIsTournamentsLoaded(true);
        setIsPaymentsLoaded(true);
      }
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (!uid) return;

    const fetchImages = async (items, getImagePath) => {
      const getUrl = async (id, image) =>
        image ? await getStorageUrl(getImagePath(id, image)) : '';

      const imgUrlPromises = items.map(async (item) =>
        getUrl(item.id, item.image)
      );

      const imgThumbUrlPromises = items.map(async (item) =>
        getUrl(item.id, addThumbnailPostfix(item.image))
      );

      const docImgPromises = items.map((item) => {
        if (item.docs)
          return {
            imgUrlPromises: item.docs.map((doc) => getUrl(item.id, doc.image)),
            imgThumbUrlPromises: item.docs.map((doc) =>
              getUrl(item.id, addThumbnailPostfix(doc.image))
            ),
          };

        return {
          imgUrlPromises: [],
          imgThumbUrlPromises: [],
        };
      });

      const [
        imgUrlsResult,
        imgThumbUrlsResult,
        docImgsResult,
        docImgThumbsResult,
      ] = await Promise.allSettled([
        Promise.allSettled(imgUrlPromises),
        Promise.allSettled(imgThumbUrlPromises),
        Promise.allSettled(
          docImgPromises.map((doc) => Promise.allSettled(doc.imgUrlPromises))
        ),
        Promise.allSettled(
          docImgPromises.map((doc) =>
            Promise.allSettled(doc.imgThumbUrlPromises)
          )
        ),
      ]);

      const imgUrls = imgUrlsResult.value.map((item) =>
        item.status === 'fulfilled' ? item.value : ''
      );

      const imgThumbUrls = imgThumbUrlsResult.value.map((item) =>
        item.status === 'fulfilled' ? item.value : ''
      );

      const docImgUrls = docImgsResult.value.map((item) =>
        item.value.map((innerItem) =>
          innerItem.status === 'fulfilled' ? innerItem.value : ''
        )
      );

      const docImgThumbUrls = docImgThumbsResult.value.map((item) =>
        item.value.map((innerItem) =>
          innerItem.status === 'fulfilled' ? innerItem.value : ''
        )
      );

      items.forEach((item, i) => {
        item.imgUrl = imgUrls[i];
        item.imgThumbUrl = imgThumbUrls[i] || imgUrls[i];
        if (item.docs) {
          item.docs.forEach((doc, k) => {
            doc.imgUrl = docImgUrls[i][k];
            doc.imgThumbUrl = docImgThumbUrls[i][k] || docImgUrls[i][k];
          });
        }
      });

      return items;
    };

    const unsubUsers = onSnapshot(collection(db, 'users'), async (snapshot) => {
      let newUsers = snapToArr(snapshot);

      newUsers = await fetchImages(
        newUsers,
        (id, imageName) => `users/${id}/${imageName}`
      );

      setUsers(newUsers);
      setIsUsersLoaded(true);
    });

    const unsubTeams = onSnapshot(
      query(collection(db, 'teams'), where('active', '==', true)),
      async (snapshot) => {
        let newTeams = snapToArr(snapshot);

        newTeams = await fetchImages(
          newTeams,
          (id, imageName) => `teams/${id}/${imageName}`
        );

        setTeams(newTeams);
        setIsTeamsLoaded(true);
      }
    );

    let unsubTournTeamsMap = {};
    let unsubTournFieldsMap = {};
    let unsubTournGamesMap = {};

    const unsubTournaments = onSnapshot(
      query(collection(db, 'tournaments'), where('active', '==', true)),
      async (snapshot) => {
        Object.values(unsubTournTeamsMap).forEach((unsub) => unsub());
        unsubTournTeamsMap = {};

        Object.values(unsubTournFieldsMap).forEach((unsub) => unsub());
        unsubTournFieldsMap = {};

        Object.values(unsubTournGamesMap).forEach((unsub) => unsub());
        unsubTournGamesMap = {};

        let newTournaments = snapToArr(snapshot);

        newTournaments = await fetchImages(
          newTournaments,
          (id, imageName) => `tournaments/${id}/${imageName}`
        );

        newTournaments.forEach((tournament) => {
          const unsubTeams = onSnapshot(
            collection(db, `tournaments/${tournament.id}/teams`),
            (teamsSnapshot) => {
              const teams = snapToArr(teamsSnapshot);
              setTournaments((prevTourn) =>
                prevTourn.map((tourn) =>
                  tourn.id === tournament.id ? { ...tourn, teams } : tourn
                )
              );
            }
          );
          unsubTournTeamsMap[tournament.id] = unsubTeams;

          const unsubFields = onSnapshot(
            collection(db, `tournaments/${tournament.id}/fields`),
            (fieldsSnapshot) => {
              const fields = snapToArr(fieldsSnapshot);
              setTournaments((prevTourn) =>
                prevTourn.map((tourn) =>
                  tourn.id === tournament.id ? { ...tourn, fields } : tourn
                )
              );
            }
          );
          unsubTournFieldsMap[tournament.id] = unsubFields;

          const unsubGames = onSnapshot(
            collection(db, `tournaments/${tournament.id}/games`),
            (gamesSnapshot) => {
              const games = snapToArr(gamesSnapshot);
              setTournaments((prevTourn) =>
                prevTourn.map((tourn) =>
                  tourn.id === tournament.id ? { ...tourn, games } : tourn
                )
              );
            }
          );
          unsubTournGamesMap[tournament.id] = unsubGames;
        });

        setTournaments(
          newTournaments.map((tourn) => ({
            ...tourn,
            startDate: getMatchMomentUTC({
              date: tourn.startDate,
              time: '00:00',
              timezone: moment.tz.guess(),
            }),
            endDate: getMatchMomentUTC({
              date: tourn.endDate,
              time: '23:59',
              timezone: moment.tz.guess(),
            }),
            teams: [],
            fields: [],
            games: [],
          }))
        );
        setIsTournamentsLoaded(true);
      }
    );

    const unsubReferees = onSnapshot(
      query(collection(db, 'referees'), where('active', '==', true)),
      async (snapshot) => {
        let newReferees = snapToArr(snapshot);

        newReferees = await fetchImages(
          newReferees,
          (id, imageName) => `referees/${id}/${imageName}`
        );

        setReferees(newReferees);
        setIsRefereesLoaded(true);
      }
    );

    return () => {
      unsubUsers();
      unsubTeams();
      unsubTournaments();
      unsubReferees();
      Object.values(unsubTournTeamsMap).forEach((unsub) => unsub());
      Object.values(unsubTournFieldsMap).forEach((unsub) => unsub());
      Object.values(unsubTournGamesMap).forEach((unsub) => unsub());
    };
  }, [uid]);

  useEffect(() => {
    if (!user) return;

    if (![UsersRoles.ADMIN, UsersRoles.COACH].includes(user.role)) return;

    const paymentsRef = collection(db, 'payments');
    let paymentsQuery;

    switch (user.role) {
      case UsersRoles.ADMIN:
        paymentsQuery = paymentsRef;
        paymentsQuery = query(paymentsRef, orderBy('time', 'desc'));
        break;
      case UsersRoles.COACH:
        paymentsQuery = query(
          paymentsRef,
          where('details.team', 'in', user.teams || []),
          orderBy('time', 'desc')
        );
        break;
      default:
        paymentsQuery = query(
          paymentsRef,
          where('details.team', '==', '__NO_ACCESS__')
        );
    }

    const unsubPayments = onSnapshot(paymentsQuery, async (snapshot) => {
      setPayments(snapToArr(snapshot));
      setIsPaymentsLoaded(true);
    });

    return () => {
      unsubPayments();
    };
  }, [user]);

  return (
    <FirebaseContext.Provider
      value={{
        user,
        users,
        teams,
        tournaments,
        referees,
        payments,
        isUsersLoaded,
        isTeamsLoaded,
        isTournamentsLoaded,
        isRefereesLoaded,
        isPaymentsLoaded,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}
