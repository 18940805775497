// Edition interface for GameBlock

import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BlockModalEditTemplate from './BlockModalEditTemplate';
import Select from './Select';
import { useFirebase } from './FirebaseContextProvider';
import { time24To12H, time12To24H, hourTo12H } from '../utils/misc';
import { defaultDuration, scheduleStartHour } from '../utils/consts';

export default function GameBlockEdit({
  show,
  onClose,
  id,
  game,
  setBracketsPopup,
}) {
  const { tournaments, teams } = useFirebase();
  const tournament = tournaments.find((tourn) => tourn.id === id);
  const { date, duration, field, referees, start, team1, team2 } = game;

  const initialValues = {
    date: date ? moment(date).toDate() : null,
    duration: duration || defaultDuration,
    field: field || null,
    referees: referees || [],
    start: start ? time24To12H(start) : hourTo12H(scheduleStartHour),
    team1: team1 || null,
    team2: team2 || null,
  };

  const validationSchema = new Yup.ObjectSchema({});

  return (
    <BlockModalEditTemplate show={show} onClose={onClose} header='Edit game'>
      <form className='game-block-edit'>
        <div className='teams'>
          <Select
            options={tournament.teams.map(
              (team) => teams.find((t) => t.id === team)?.name
            )}
          />
        </div>
      </form>
      {/* <p className='division'>{`${sport}, ${division}`}</p>
      {(team1 || team2) && (
        <p className='text font-medium'>{`${team1 !== null ? team1 : 'TBD'} ${
          score1 !== null || score2 !== null ? score1 + ' : ' + score2 : ' - '
        } ${team2 !== null ? team2 : 'TBD'}`}</p>
      )}
      <p className='referees'>
        Referees:{' '}
        {referees.map((referee) => referee.replace(/ /g, '\u00A0')).join(', ')}
      </p>
      {round !== null && placement !== null && (
        <a
          className='font-medium primary round-game'
          onClick={() => setBracketsPopup({ id: gameId, mode: 'set' })}
        >{`Playoff: round ${round + 1}, game ${placement + 1}`}</a>
      )}
      {!(round !== null && placement !== null) && (
        <a
          className='font-medium primary round-game'
          onClick={() => setBracketsPopup({ id: gameId, mode: 'set' })}
        >
          Set playoff game
        </a>
      )} */}
    </BlockModalEditTemplate>
  );
}
