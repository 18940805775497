// Main admin panel interface

import Card from './Card';
import Tabs from './Tabs';
import TeamsList from './TeamsList';
import TournamentsList from './TournamentsList';
import PaymentsList from './PaymentsList';
import RefereesList from './RefereesList';
import './AdminPanelCard.scss';

export default function AdminPanelCard() {
  return (
    <Card header='Admin panel' className='admin-panel'>
      <Tabs
        className='admin-panel-tabs'
        tabs={[
          {
            title: 'Tournaments',
            hash: '#tournaments',
            component: (
              <div className='block component-wrap'>
                <TournamentsList controls />
              </div>
            ),
          },
          {
            title: 'Teams',
            hash: '#teams',
            component: (
              <div className='block component-wrap'>
                <TeamsList controls />
              </div>
            ),
          },
          {
            title: 'Referees',
            hash: '#referees',
            component: (
              <div className='block component-wrap'>
                <RefereesList />
              </div>
            ),
          },
          {
            title: 'Payments',
            hash: '#payments',
            component: (
              <div className='block component-wrap'>
                <PaymentsList />
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
}
