// User element in header including menu

import { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { useFirebase } from './FirebaseContextProvider';
import ContextMenu from './ContextMenu';
import './User.scss';

const userMenuOptions = [{ item: 'Log out', onClick: () => signOut(auth) }];

export default function User() {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { user } = useFirebase();

  if (!user) return;

  return (
    <div className='user' onClick={() => setShowUserMenu(true)}>
      <p className='icon fa-user' />
      <p className='name upper font-medium'>{user.name}</p>
      <ContextMenu
        show={showUserMenu}
        onClose={() => setShowUserMenu(false)}
        options={userMenuOptions}
        className='user-menu'
      />
    </div>
  );
}
