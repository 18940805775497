// Input field with a error message

import './Input.scss';

export default function Input({
  name,
  placeholder,
  type,
  onChange,
  onBlur,
  value,
  error,
  className,
  small,
}) {
  return (
    <div className={'input' + (className ? ' ' + className : '')}>
      <input
        className={(error ? 'error' : '') + (small ? ' small' : '')}
        name={name}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      <p className={'error-msg' + (error ? ' visible' : '')}>{error}</p>
    </div>
  );
}
