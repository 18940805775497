// Interface to set a game score for Scheduler

import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BlockModalEditTemplate from './BlockModalEditTemplate';
import Input from './Input';
import './GameBlockScore.scss';

export default function GameBlockScore({
  id,
  gameId,
  show,
  onClose,
  setGame,
  team1,
  team2,
  score1,
  score2,
}) {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!show) setError(null);
  }, [show]);

  const initialValues = {
    score1: score1 !== null ? score1 : '',
    score2: score2 !== null ? score2 : '',
  };

  const validationSchema = new Yup.ObjectSchema({
    score1: Yup.string().matches(/^\d*$/, 'Invalid'),
    score2: Yup.string().matches(/^\d*$/, 'Invalid'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { score1, score2 } = values;
      try {
        await setGame({
          id,
          gameId,
          score1: score1 !== '' ? score1 : null,
          score2: score2 !== '' ? score2 : null,
        });
        onClose();
      } catch (error) {
        setError(error.message);
      }
    },
  });

  return (
    <BlockModalEditTemplate show={show} onClose={onClose} header='Set score'>
      <form className='game-block-score-form' onSubmit={formik.handleSubmit}>
        <div className='input-wrap'>
          <div className='team1'>
            <p className='font-medium'>{team1}</p>
            <Input
              name='score1'
              placeholder='Score'
              type='number'
              {...formik.getFieldProps('score1')}
              error={
                formik.touched.score1 && formik.errors.score1
                  ? formik.errors.score1
                  : null
              }
            />
          </div>
          <div className='team2'>
            <p className='font-medium'>{team2}</p>
            <Input
              name='score2'
              placeholder='Score'
              type='number'
              {...formik.getFieldProps('score2')}
              error={
                formik.touched.score2 && formik.errors.score2
                  ? formik.errors.score2
                  : null
              }
            />
          </div>
        </div>
        <p className={'error' + (error ? ' show' : '')}>Error: {error}</p>
        <button className='signin-btn' type='submit'>
          Set
        </button>
      </form>
    </BlockModalEditTemplate>
  );
}
