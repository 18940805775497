// Used to visualize and edit a tournament schedule

import { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';
import TimeLine from './TimeLine';
import Field from './Field';
import ColorLegend from './ColorLegend';
import InputDate from './InputDate';
import Brackets from './Brackets';
import GetClickOutside from './GetClickOutside';
import FieldsList from './FieldsList';
import { getTeamDivision } from '../utils/misc';
import './Scheduler.scss';

const timeSlotHeight = 36;

export default function Scheduler({
  id,
  teams,
  games,
  setGame,
  date,
  setDate,
  fields,
  startDate,
  endDate,
  timeSlots,
  colorLegend,
}) {
  const [bracketsPopup, setBracketsPopup] = useState(null);
  const [filteredGames, setFilteredGames] = useState([]);

  useEffect(() => {
    setFilteredGames(
      games.filter((game) => moment(game.date).isSame(date, 'day'))
    );
  }, [games, date]);

  const setGameFilter = async (args) => {
    setFilteredGames((prevFilteredGames) =>
      prevFilteredGames.map((game) =>
        game.id === args.gameId ? { ...game, ...args, id: args.gameId } : game
      )
    );
    await setGame(args);
  };

  return (
    <div className='scheduler'>
      <div className='control-wrap'>
        <div className='filter-wrap'>
          <InputDate
            value={date.toDate()}
            onChange={(newDate) => setDate(moment(newDate))}
            className='date'
            minDate={startDate.toDate()}
            maxDate={endDate.toDate()}
            placeholder='Day'
          />
          <FieldsList id={id} fields={fields} />
        </div>
        <ColorLegend
          colorLegend={Object.fromEntries(
            Object.entries(colorLegend).filter(([key]) =>
              new Set(
                filteredGames.flatMap((game) => {
                  const team1 = teams.find((team) => team.id === game.team1);
                  const team2 = teams.find((team) => team.id === game.team2);
                  return [getTeamDivision(team1), getTeamDivision(team2)];
                })
              ).has(key)
            )
          )}
        />
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className='timeline-fields-wrap'>
          <TimeLine timeSlots={timeSlots} timeSlotHeight={timeSlotHeight} />
          {fields
            .sort((a, b) => a.sortIndex - b.sortIndex)
            .map((field) => (
              <Field
                key={field.id}
                field={field.id}
                id={id}
                header={field.name}
                timeSlotHeight={timeSlotHeight}
                timeSlots={timeSlots}
                teams={teams}
                games={filteredGames.filter((game) => game.field === field.id)}
                colorLegend={colorLegend}
                setBracketsPopup={setBracketsPopup}
                setGame={setGameFilter}
              />
            ))}
        </div>
      </DndProvider>
      <div className={'brackets-popup modal' + (bracketsPopup ? ' show' : '')}>
        <GetClickOutside
          active={!!bracketsPopup}
          onClick={() => setBracketsPopup(null)}
        >
          {bracketsPopup && (
            <div className='block'>
              <Brackets
                games={games}
                setGame={setGameFilter}
                fields={fields}
                timeSlots={timeSlots}
                highlight={bracketsPopup ? bracketsPopup.id : undefined}
                mode={bracketsPopup ? bracketsPopup.mode : undefined}
              />
              <button
                className='close-btn fa-cross transparent'
                onClick={() => setBracketsPopup(null)}
              />
            </div>
          )}
        </GetClickOutside>
      </div>
    </div>
  );
}
