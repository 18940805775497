// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, getDownloadURL, ref } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBMbNQ8Tzi-eYgtJ8Q6tix2jbZi3qFqT9s',
  authDomain: 'muva-sport.firebaseapp.com',
  projectId: 'muva-sport',
  storageBucket: 'muva-sport.appspot.com',
  messagingSenderId: '115484975750',
  appId: '1:115484975750:web:ae01ed0361bceb0b78a707',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
const functions = getFunctions(app);

// Firebase cloud functions
export const teamUpdate = httpsCallable(functions, 'team-update');
export const teamCreate = httpsCallable(functions, 'team-create');
export const tournamentUpdate = httpsCallable(functions, 'tournament-update');
export const tournamentCreate = httpsCallable(functions, 'tournament-create');
export const tournamentTeamSetStatus = httpsCallable(
  functions,
  'tournament-teamSetStatus'
);
export const tournamentFieldCreate = httpsCallable(
  functions,
  'tournament-fieldCreate'
);
export const tournamentFieldUpdate = httpsCallable(
  functions,
  'tournament-fieldUpdate'
);
export const tournamentFieldDelete = httpsCallable(
  functions,
  'tournament-fieldDelete'
);
export const tournamentGameUpdate = httpsCallable(
  functions,
  'tournament-gameUpdate'
);
export const userUpdate = httpsCallable(functions, 'user-update');
export const userRemoveTeam = httpsCallable(functions, 'user-removeTeam');
export const userUseToken = httpsCallable(functions, 'user-useToken');
export const tokenCreate = httpsCallable(functions, 'token-create');
export const tokenGet = httpsCallable(functions, 'token-get');
export const refereeUpdate = httpsCallable(functions, 'referee-update');
export const refereeCreate = httpsCallable(functions, 'referee-create');
export const authCheckAuthByEmail = httpsCallable(
  functions,
  'auth-checkAuthByEmail'
);
export const squareCreatePaymentLink = httpsCallable(
  functions,
  'square-createPaymentLink'
);

// Firebase utils
export const getStorageUrl = async (path) => {
  try {
    return await getDownloadURL(ref(storage, path));
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const sendEmailLink = async ({ email, token }) => {
  try {
    await sendSignInLinkToEmail(auth, email, {
      url: `${process.env.REACT_APP_ROOT_URL}/emaillinksignin${
        token ? '/' + token : ''
      }`,
      handleCodeInApp: true,
    });
  } catch (error) {
    throw new Error('Unable to send auth link');
  }
};

export const isEmailLink = () =>
  isSignInWithEmailLink(auth, window.location.href);

export const signInByLink = async (email) =>
  await signInWithEmailLink(auth, email, window.location.href);
