// Used with the Scheduler to visualize a timeline for each field

import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import moment from 'moment';
import GameBlock, { ItemTypes } from './GameBlock';
import { getTeamDivision } from '../utils/misc';
import { scheduleStartHour, scheduleStepMin } from '../utils/consts';
import './Field.scss';

const timeSlotsInHour = Math.floor(60 / scheduleStepMin);

export default function Field({
  id,
  field,
  header,
  timeSlotHeight,
  timeSlots,
  teams,
  games,
  colorLegend,
  setBracketsPopup,
  setGame,
}) {
  const bodyRef = useRef();

  const [, dropRef] = useDrop({
    accept: [ItemTypes.GAME_BLOCK, ItemTypes.RESIZE_HANDLE],
    drop: async (item, monitor) => {
      const dropTarget = bodyRef.current;
      if (!dropTarget) return;

      const sourceOffset = monitor.getSourceClientOffset();
      if (!sourceOffset) return;

      const rect = dropTarget.getBoundingClientRect();
      const offsetY = sourceOffset.y - rect.top;
      const slotIndex = Math.max(
        0,
        Math.min(timeSlots.length - 1, Math.floor(offsetY / timeSlotHeight))
      );
      const totalMinutes = scheduleStartHour * 60 + slotIndex * scheduleStepMin;
      const hour = Math.floor(totalMinutes / 60)
        .toString()
        .padStart(2, '0');
      const min = (totalMinutes % 60).toString().padStart(2, '0');
      const start = `${hour}:${min}`;

      try {
        if (item.type === ItemTypes.GAME_BLOCK) {
          await setGame({
            id,
            gameId: item.id,
            field,
            start,
          });
        } else if (item.resizeType) {
          let duration;
          const game = games.find((game) => game.id === item.id);

          const diffMin = moment(game.start, 'HH:mm').diff(
            moment(start, 'HH:mm'),
            'minutes'
          );

          if (item.resizeType === 'start') {
            duration = game.duration + diffMin;
            if (duration < scheduleStepMin) duration = scheduleStepMin;
            await setGame({
              id,
              gameId: item.id,
              start,
              duration,
            });
          } else if (item.resizeType === 'end') {
            duration = -1 * diffMin + scheduleStepMin;
            if (duration < scheduleStepMin) duration = scheduleStepMin;
            await setGame({
              id,
              gameId: item.id,
              duration,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <div className='field'>
      <h6 className='header'>{header}</h6>
      <div
        className='body'
        style={{ height: timeSlotHeight * timeSlots.length }}
        ref={bodyRef}
      >
        {timeSlots
          .filter((timeSlot, i) => i % timeSlotsInHour === 3)
          .map((timeSlot, i) => {
            if (i === 0) return null;
            return (
              <div
                key={timeSlot}
                className='divider'
                style={{ top: timeSlotsInHour * timeSlotHeight * i }}
              />
            );
          })}
        <div className='drop-zone' ref={dropRef}>
          {games.map((game) => {
            const team1 = teams.find((team) => team.id === game.team1);

            return (
              <GameBlock
                key={game.id}
                id={id}
                game={game}
                color={colorLegend[getTeamDivision(team1)]}
                timeSlotHeight={timeSlotHeight}
                setBracketsPopup={setBracketsPopup}
                setGame={setGame}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
