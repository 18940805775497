// The button that appears in the bottom right corner when you scroll a page down

import { useState, useEffect } from 'react';
import './ScrollToTopButton.scss';

export default function ScrollToTopButton() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 500) {
        setShow(true);
      } else setShow(false);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <button
      className={'scroll-to-top-button fa-chevron-up' + (show ? ' show' : '')}
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
    />
  );
}
