// Using this as titles for tournament stages

import './BracketsTitle.scss';

export default function BracketsTitle({ data }) {
  return (
    <div className='brackets-title'>
      <h6>{data.title}</h6>
    </div>
  );
}
