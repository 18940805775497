// We use it in TournamentCard component to manage tournament fields

import { useState, useEffect } from 'react';
import MultiInput from './MultiInput';
import {
  tournamentFieldCreate,
  tournamentFieldUpdate,
  tournamentFieldDelete,
} from '../utils/firebase';
import './FieldsList.scss';

export default function FieldsList({ id, fields }) {
  const getFieldNames = (fields) =>
    fields.sort((a, b) => a.sortIndex - b.sortIndex).map((field) => field.name);

  const [fieldNames, setFieldNames] = useState(getFieldNames(fields));

  useEffect(() => {
    setFieldNames(getFieldNames(fields));
  }, [fields]);

  const onChangeFields = async (newFields, info) => {
    const { action, index, item } = info;
    let sortIndex, replacingField;

    setFieldNames(newFields);

    try {
      switch (action) {
        case 'add':
          await tournamentFieldCreate({ id, fieldName: item });
          break;
        case 'edit':
          await tournamentFieldUpdate({
            id,
            fieldId: fields[index].id,
            fieldName: item,
          });
          break;
        case 'delete':
          await tournamentFieldDelete({ id, fieldId: fields[index].id });
          break;
        case 'up':
          sortIndex = fields[index].sortIndex;
          replacingField = fields
            .filter((field) => field.sortIndex < sortIndex)
            .reduce(
              (prev, curr) =>
                prev === null || prev.sortIndex < curr.sortIndex ? curr : prev,
              null
            );
          await tournamentFieldUpdate({
            id,
            fieldId: fields[index].id,
            sortIndex: sortIndex - 1,
          });
          if (replacingField)
            await tournamentFieldUpdate({
              id,
              fieldId: replacingField.id,
              sortIndex: sortIndex,
            });
          break;
        case 'down':
          sortIndex = fields[index].sortIndex;
          replacingField = fields
            .filter((field) => field.sortIndex > sortIndex)
            .reduce(
              (prev, curr) =>
                prev === null || prev.sortIndex > curr.sortIndex ? curr : prev,
              null
            );
          await tournamentFieldUpdate({
            id,
            fieldId: fields[index].id,
            sortIndex: sortIndex + 1,
          });
          if (replacingField)
            await tournamentFieldUpdate({
              id,
              fieldId: replacingField.id,
              sortIndex: sortIndex,
            });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MultiInput
      value={fieldNames}
      onChange={onChangeFields}
      defaultNew={
        'Field ' +
        (Math.max(
          0,
          ...fieldNames
            .map((s) => s.match(/^Field (\d+)$/))
            .filter(Boolean)
            .map((m) => +m[1])
        ) +
          1)
      }
    />
  );
}
