// Common template to visualize a modal popping up block with some content inside

import GetClickOutside from './GetClickOutside';
import './BlockModalEditTemplate.scss';

export default function BlockModalEditTemplate({
  show,
  onClose,
  header,
  children,
}) {
  return (
    <div className={'block-modal-edit-template' + (show ? ' show' : '')}>
      <div className='cover' />
      <GetClickOutside active={show} onClick={onClose}>
        <div className='white-back'>
          <div className='header'>
            <h6>{header}</h6>
            <button
              onClick={onClose}
              className='close-btn fa-cross small white'
            />
          </div>
          <div className='content-wrap block'>{children}</div>
        </div>
      </GetClickOutside>
    </div>
  );
}
