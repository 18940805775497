// Common visual element used with Select, MultiSelect, MultiInput components

import './ChevronUpDown.scss';

export default function ChevronUpDown({ up = false }) {
  return (
    <p
      className={
        'chevron-up-down ' + (up ? 'fa-chevron-up' : 'fa-chevron-down')
      }
    />
  );
}
