// Custom select element

import { useState } from 'react';
import ChevronUpDown from './ChevronUpDown';
import GetClickOutside from './GetClickOutside';
import './Select.scss';

export default function Select({
  options,
  value,
  onChange,
  small,
  className,
  placeholder,
  error,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={
        'select' + (small ? ' small' : '') + (className ? ' ' + className : '')
      }
    >
      <GetClickOutside active={isOpen} onClick={() => setIsOpen(false)}>
        <div
          className={'selected-option' + (error ? ' error' : '')}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className={'value' + (value ? '' : ' placeholder')}>
            {value || placeholder}
          </p>
          <ChevronUpDown up={isOpen} />
          <p className={'error-msg' + (error ? ' visible' : '')}>{error}</p>
        </div>
        <ul className={'options' + (isOpen ? ' open' : '')}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      </GetClickOutside>
    </div>
  );
}
