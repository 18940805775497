// Used with the Scheduler to visualize a tournament timeline

import './TimeLine.scss';

export default function TimeLine({ timeSlots, timeSlotHeight }) {
  return (
    <ul className='time-line'>
      {timeSlots.map((timeSlot) => (
        <li
          key={timeSlot}
          style={{ height: timeSlotHeight }}
          className={timeSlot.includes(':00') ? 'minutes-00' : ''}
        >
          {timeSlot.toLowerCase()}
        </li>
      ))}
    </ul>
  );
}
