// Showing a game table

import './GameListTable.scss';

export const timeStr = (timeSlots, start, duration) => {
  const startTime = timeSlots[start] || '';
  const endTime = timeSlots[start + duration] || '';
  return `${startTime.replace(/^(\d:)/, '0$1')} - ${endTime.replace(
    /^(\d:)/,
    '0$1'
  )}`
    .toLowerCase()
    .replace(/( (?:am|pm))(?=.*\1)/, '');
};

export default function GameListTable({
  games,
  fields,
  timeSlots,
  colorLegend,
  noFieldCol,
}) {
  return (
    <div className='game-list-table'>
      <div className='header'>
        <h6 className='col-1'>Time</h6>
        <h6 className='col-2'>Sport</h6>
        <h6 className='col-3'>Division</h6>
        <h6 className='col-4'>Game</h6>
        <h6 className='col-5'>Referees</h6>
        {!noFieldCol && <h6 className='col-6'>Field</h6>}
      </div>

      <div className='lines'>
        {games.map((game) => {
          const {
            id,
            start,
            duration,
            field,
            text,
            team1,
            team2,
            score1,
            score2,
            referees,
            division,
            sport,
          } = game;
          const time = timeStr(timeSlots, start, duration);
          const fieldName = fields.find((f) => f.id === field)?.name || '';

          return (
            <div key={id} className='line'>
              <p
                className='col-1'
                style={{
                  backgroundColor: `${colorLegend[`${sport}, ${division}`]}30`,
                }}
              >
                {time}
              </p>
              <p
                className='col-2'
                style={{
                  backgroundColor: `${colorLegend[`${sport}, ${division}`]}30`,
                }}
              >
                {sport}
              </p>
              <p
                style={{
                  backgroundColor: `${colorLegend[`${sport}, ${division}`]}30`,
                }}
                className='col-3 '
              >
                {division}
              </p>
              <p
                style={{
                  backgroundColor: `${colorLegend[`${sport}, ${division}`]}30`,
                }}
                className='col-4  font-medium'
              >
                {text || `${team1} - ${team2}`}
              </p>
              <p
                style={{
                  backgroundColor: `${colorLegend[`${sport}, ${division}`]}30`,
                }}
                className='col-5 '
              >
                {referees
                  .map((referee) => referee.replace(/ /g, '\u00A0'))
                  .join(', ')}
              </p>
              {!noFieldCol && (
                <p
                  style={{
                    backgroundColor: `${
                      colorLegend[`${sport}, ${division}`]
                    }30`,
                  }}
                  className='col-6 '
                >
                  {fieldName}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
