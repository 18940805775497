// Multi-select dropdown list with checkboxes

import { useState } from 'react';
import GetClickOutside from './GetClickOutside';
import CheckBox from './CheckBox';
import './MultiSelect.scss';
import ChevronUpDown from './ChevronUpDown';

export default function MultiSelect({
  options,
  value,
  onChange,
  small,
  className,
  placeholder,
  error,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={
        'multi-select' +
        (small ? ' small' : '') +
        (className ? ' ' + className : '')
      }
    >
      <GetClickOutside active={isOpen} onClick={() => setIsOpen(false)}>
        <div
          className={'selected-options' + (error ? ' error' : '')}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className={'value' + (value.length > 0 ? '' : ' placeholder')}>
            {value.length > 0 ? value.join(', ') : placeholder}
          </p>
          <ChevronUpDown up={isOpen} />
          <p className={'error-msg' + (error ? ' visible' : '')}>{error}</p>
        </div>
        <div className={'options' + (isOpen ? ' open' : '')}>
          {[
            <CheckBox
              key='all'
              label='Select all'
              small={small}
              value={options.every((option) => value.includes(option))}
              onChange={(checked) => {
                if (checked) {
                  onChange([...new Set(options)]);
                } else onChange([]);
              }}
              className='select-all'
            />,
            ...options.map((option, index) => (
              <CheckBox
                key={index}
                label={option}
                small={small}
                value={value.includes(option)}
                onChange={(checked) => {
                  if (checked) {
                    onChange([...new Set([...value, option])]);
                  } else onChange(value.filter((val) => val !== option));
                }}
              />
            )),
          ]}
        </div>
      </GetClickOutside>
    </div>
  );
}
