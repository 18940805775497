// Tournament details interface

import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TeamsList from './TeamsList';
import CardTemplate from './CardTemplate';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import TournamentCardEdit from './TournamentCardEdit';
import Tabs from './Tabs';
import Scheduler from './Scheduler';
import GameList from './GameList';
import Brackets from './Brackets';
import { UsersRoles } from '../utils/enums';
import { useFirebase } from './FirebaseContextProvider';
import { formatDateRange, getTeamDivision, switchGender } from '../utils/misc';
import { tournamentGameUpdate, tournamentUpdate } from '../utils/firebase';
import {
  scheduleStartHour,
  scheduleEndHour,
  scheduleStepMin,
} from '../utils/consts';
import './TournamentCard.scss';

const colors = {
  raspberry: '#e85192',
  grassGreen: '#4caf50',
  purple: '#a56eff',
  orange: '#ffa04c',
  lightYellow: '#eeec49',
  azure: '#39d2b4',
  blue: '#28c0e1',
};

const generateTimeSlots = () => {
  const slots = [];
  const totalMinutes = (scheduleEndHour - scheduleStartHour) * 60;

  for (let minutes = 0; minutes < totalMinutes; minutes += scheduleStepMin) {
    const hour = Math.floor(minutes / 60) + scheduleStartHour;
    const min = minutes % 60;
    const label = new Date(0, 0, 0, hour, min).toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    slots.push(label);
  }

  return slots;
};

const timeSlots = generateTimeSlots();

export default function TournamentCard({ tournament }) {
  const {
    id,
    imgUrl,
    imgThumbUrl,
    name,
    location,
    startDate,
    endDate,
    sports,
    genders,
    ageFrom,
    ageTo,
    fields,
    games,
  } = tournament;

  const [date, setDate] = useState(startDate);
  const navigate = useNavigate();
  const { user, teams } = useFirebase();

  const divisions = useMemo(
    () => [
      ...new Set(
        games.flatMap((game) => {
          const team1 = teams.find((team) => team.id === game.team1);
          const team2 = teams.find((team) => team.id === game.team2);
          return [getTeamDivision(team1), getTeamDivision(team2)];
        })
      ),
    ],
    [games]
  );

  const colorLegend = useMemo(() => {
    const colorValues = Object.values(colors);
    const legend = {};
    divisions.forEach((division, i) => {
      legend[division] = colorValues[i];
    });
    return legend;
  }, [divisions]);

  const userIsAdmin = user.role === UsersRoles.ADMIN;

  const onDelete = async () => {
    try {
      await tournamentUpdate({ id, active: false });
    } catch (error) {
      console.error(error?.message);
    }
  };

  return (
    <CardTemplate
      header='Tournament'
      className='tournament-card'
      cardBtnText='Back'
      cardBtnOnClick={() => navigate(-1)}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle='Tournament emblem'
      name={name}
      details={
        <PropItemsBlock>
          <p className='fa-calendar'>
            {' ' + formatDateRange(startDate || moment(), endDate || moment())}
          </p>
          <PropItem prop='Location' value={location} />
          <PropItem prop='Sport' value={sports?.join(', ')} />
          <PropItem
            prop='Division'
            value={`${genders
              ?.map((gender) => switchGender(gender))
              .join(', ')}, ${
              ageFrom === ageTo ? ageFrom : ageFrom + ' - ' + ageTo
            }`}
          />
        </PropItemsBlock>
      }
      onDelete={userIsAdmin && onDelete}
      afterDeleteNavPath='/admin#tournaments'
      editForm={userIsAdmin && <TournamentCardEdit tournament={tournament} />}
    >
      <Tabs
        className='tournament-card-tabs'
        tabs={[
          {
            title: 'Teams',
            hash: '#teams',
            component: (
              <div className='block teams-list-wrap'>
                <TeamsList tournamentId={id} />
              </div>
            ),
          },
          {
            title: 'Schedule',
            hash: '#schedule',
            component: (
              <div className='block'>
                <Scheduler
                  id={id}
                  teams={teams}
                  games={games}
                  setGame={tournamentGameUpdate}
                  date={date}
                  setDate={setDate}
                  fields={fields}
                  sports={sports}
                  startDate={startDate}
                  endDate={endDate}
                  timeSlots={timeSlots}
                  colorLegend={colorLegend}
                />
              </div>
            ),
          },
          {
            title: 'Games',
            hash: '#games',
            component: (
              <div className='block'>
                <GameList
                  tournamentName={name}
                  tournamentLocation={location}
                  games={games}
                  fields={fields}
                  timeSlots={timeSlots}
                  colorLegend={colorLegend}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            ),
          },
          {
            title: 'Standings',
            hash: '#standings',
            component: <div className='block' />,
          },
          {
            title: 'Brackets',
            hash: '#brackets',
            component: (
              <div className='block'>
                <Brackets
                  games={games}
                  setGame={tournamentGameUpdate}
                  fields={fields}
                  timeSlots={timeSlots}
                />
              </div>
            ),
          },
        ]}
      />
    </CardTemplate>
  );
}
