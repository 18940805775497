// Referees list with control elements

import { Link } from 'react-router-dom';
import { useFirebase } from './FirebaseContextProvider';
import './RefereesList.scss';

export default function RefereesList() {
  const { referees } = useFirebase();

  return (
    <div className='referees-list'>
      <div className='controls'>
        <div className='filters' />
        <Link to='/referees/new?edit=true' className='button small bw'>
          Add referee
        </Link>
      </div>
      <ul className='tab-header'>
        <li className='col-1' />
        <li className='col-2'>Name</li>
        <li className='col-3'>E-mail</li>
        <li className='col-4'>Phone</li>
        <li className='col-34'>Contacts</li>
      </ul>
      <div className='tab-body'>
        {referees.map((referee) => {
          const { id, imgThumbUrl, name, phone, email } = referee;

          return (
            <Link key={id} to={'/referees/' + id} className='referee'>
              <div
                className='col-1 bg-image wh-64'
                style={{
                  backgroundImage: `url(${imgThumbUrl})`,
                }}
              />
              <p className='col-2'>{name}</p>
              <p className='col-3'>{email}</p>
              <p className='col-4'>{phone}</p>
              <p className='col-34'>
                {email}
                <br />
                {phone}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
