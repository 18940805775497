// Root component with routing definition

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
  useRouteError,
  redirect,
} from 'react-router-dom';
import Layout from './components/Layout';
import PageLogin from './pages/PageLogin';
import PageAdmin from './pages/PageAdmin';
import PageTournament from './pages/PageTournament';
import PageTeam from './pages/PageTeam';
import PagePlayer from './pages/PagePlayer';
import PageCoach from './pages/PageCoach';
import PageReferee from './pages/PageReferee';
import PageNone from './pages/PageNone';
import PageInvite from './pages/PageInvite';
import PageAuth from './pages/PageAuth';
import PageEmailLinkSignIn from './pages/PageEmailLinkSignIn';
import PagePaymentSuccess from './pages/PagePaymentSuccess';
import FirebaseContextProvider, {
  useFirebase,
} from './components/FirebaseContextProvider';
import ModalProvider from './components/ModalProvider';
import Spinner from './components/Spinner';
import { UsersRoles } from './utils/enums';
import { tokenGet, isEmailLink } from './utils/firebase';

const PrivateRoutes = () => {
  const { user, isUsersLoaded } = useFirebase();

  if (!isUsersLoaded)
    return (
      <Layout screenSized>
        <Spinner show={true} />
      </Layout>
    );

  return user ? <Outlet /> : <Navigate to='/auth' />;
};

const PageError = () => {
  let { status, data } = useRouteError();
  return (
    <Layout className='page-error'>
      <h1>{`${status} ${data}`}</h1>
    </Layout>
  );
};

const RouterWrap = () => {
  const {
    users,
    teams,
    tournaments,
    referees,
    isUsersLoaded,
    isTeamsLoaded,
    isTournamentsLoaded,
    isRefereesLoaded,
  } = useFirebase();

  const router = createBrowserRouter([
    { path: '/login', element: <PageLogin />, errorElement: <PageError /> },
    {
      path: '/invite/:token',
      element: <PageInvite />,
      errorElement: <PageError />,
      loader: ({ params }) => ({
        token: params.token,
      }),
    },
    {
      path: '/auth/:token?',
      element: <PageAuth />,
      errorElement: <PageError />,
      loader: async ({ params }) => {
        const token = params.token;

        if (token) {
          // User have an invite
          try {
            const res = await tokenGet({ id: token });
            return { token, ...res.data };
          } catch (error) {
            const status = error?.code === 'functions/internal' ? 500 : 400;
            const errorMessage =
              error?.message || `Error: unable to read token ${token}`;
            throw new Response(errorMessage, {
              status,
            });
          }
        }

        return {};
      },
    },
    {
      path: '/emaillinksignin/:token?',
      element: <PageEmailLinkSignIn />,
      errorElement: <PageError />,
      loader: async ({ params }) => {
        const token = params.token;

        if (!isEmailLink())
          throw new Response('Error: the link is not valid', { status: 400 });

        if (token) {
          // User have an invite
          try {
            await tokenGet({ id: token });
            return { token };
          } catch (error) {
            const status = error?.code === 'functions/internal' ? 500 : 400;
            const errorMessage =
              error?.message || `Error: unable to read token ${token}`;
            throw new Response(errorMessage, {
              status,
            });
          }
        }

        return {};
      },
    },
    {
      path: '/payment-success',
      element: <PagePaymentSuccess />,
      errorElement: <PageError />,
    },
    {
      element: <PrivateRoutes />,
      errorElement: <PageError />,
      children: [
        { path: '/admin', element: <PageAdmin /> },
        {
          path: '/tournaments/:tournamentId',
          element: <PageTournament />,
          loader: async ({ params, request }) => {
            const id = params.tournamentId;
            const url = new URL(request.url);
            const editParam = url.searchParams.get('edit');
            if (id === 'new') {
              if (!editParam) return redirect('/admin#tournaments');
              return { id };
            }
            if (!isTournamentsLoaded) return { loading: true };
            const tourn = tournaments.find((tourn) => tourn.id === id);
            if (tourn) return tourn;
            throw new Response(
              `Error: no route matches url "/tournaments/${id}"`,
              { status: 404 }
            );
          },
        },
        {
          path: '/teams/:teamId',
          element: <PageTeam />,
          loader: async ({ params, request }) => {
            const id = params.teamId;
            const url = new URL(request.url);
            const editParam = url.searchParams.get('edit');
            if (id === 'new') {
              if (!editParam) return redirect('/admin#teams');
              return { id };
            }
            if (!isTeamsLoaded) return { loading: true };
            const team = teams.find((team) => team.id === id);
            if (team) return team;
            throw new Response(`Error: no route matches url "/teams/${id}"`, {
              status: 404,
            });
          },
        },
        {
          path: '/players/:playerId',
          element: <PagePlayer />,
          loader: async ({ params }) => {
            if (!isUsersLoaded) return { loading: true };
            const id = params.playerId;
            const player = users
              .filter((user) => user.role === UsersRoles.PLAYER) // Copy it to coaches and none routes too
              .find((user) => user.id === id);
            if (player) return player;
            throw new Response(`Error: no route matches url "/players/${id}"`, {
              status: 404,
            });
          },
        },
        {
          path: '/coaches/:coachId',
          element: <PageCoach />,
          loader: async ({ params }) => {
            if (!isUsersLoaded) return { loading: true };
            const id = params.coachId;
            const coach = users.find((user) => user.id === id);
            if (coach) return coach;
            throw new Response(`Error: no route matches url "/coaches/${id}"`, {
              status: 404,
            });
          },
        },
        {
          path: '/referees/:refereeId',
          element: <PageReferee />,
          loader: async ({ params, request }) => {
            const id = params.refereeId;
            const url = new URL(request.url);
            const editParam = url.searchParams.get('edit');

            if (id === 'new') {
              if (!editParam) return redirect('/admin#referees');
              return { id };
            }

            if (!isRefereesLoaded) return { loading: true };

            const referee = referees.find((ref) => ref.id === id);
            if (referee) return referee;

            throw new Response(
              `Error: no route matches url \"/referees/${id}\"`,
              {
                status: 404,
              }
            );
          },
        },
        {
          path: '/none/:noneId',
          element: <PageNone />,
          loader: async ({ params }) => {
            if (!isUsersLoaded) return { loading: true };
            const id = params.noneId;
            const none = users.find((user) => user.id === id);
            if (none) return none;
            throw new Response(`Error: no route matches url "/none/${id}"`, {
              status: 404,
            });
          },
        },
      ],
    },
    { path: '/', element: <Navigate to='/auth' replace /> },
  ]);

  return <RouterProvider router={router} />;
};

function App() {
  return (
    <FirebaseContextProvider>
      <ModalProvider>
        <RouterWrap />
      </ModalProvider>
    </FirebaseContextProvider>
  );
}

export default App;
