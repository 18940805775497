// Module to contain consts

export const fileSizeLimit = 5000000; // File size limit to upload
export const imgThumbPostfix = '_thumb'; // Postfix for image thumbnail
export const storageEmailForSignIn = 'signInEmail'; // Local storage variable used to keep an auth email
export const defaultTimeZone = 'America/Los_Angeles'; // Default timezone for storing all dates and times
export const scheduleStartHour = 8; // Hour when the schedule starts
export const scheduleEndHour = 22; // Hour when the schedule ends
export const scheduleStepMin = 15; // Minimum scheduling interval in minutes
export const defaultDuration = 30; // Default game duration in minutes
