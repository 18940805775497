// Using this as a part of MultiInput component

import { useState } from 'react';
import MultiInputItemEdit from './MultiInputItemEdit';
import './MultiInputItem.scss';

export default function MultiInputItem({
  item,
  small,
  onEdit,
  onDelete,
  onUp,
  onDown,
}) {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className={'multi-input-item' + (small ? ' small' : '')}>
      <div className='item-btns-wrap'>
        <div className={'no-edit' + (editMode ? ' edit-mode' : '')}>
          <p className='item'>{item}</p>
          <div className='btns-wrap'>
            <button
              className='up-btn small transparent fa-up-arrow'
              onClick={onUp}
            />
            <button
              className='down-btn small transparent fa-down-arrow'
              onClick={onDown}
            />
            <button
              className='edit-btn small transparent fa-pen'
              onClick={() => setEditMode(true)}
            />
            <button className='delete-btn small transparent' onClick={onDelete}>
              <span className='danger fa-cross' />
            </button>
          </div>
        </div>
        <MultiInputItemEdit
          className='edit'
          onClose={() => setEditMode(false)}
          onSave={(newItem) => onEdit(newItem)}
          small
          initValue={item}
          show={editMode}
        />
      </div>
    </div>
  );
}
