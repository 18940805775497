// Multi-input dropdown with arrangment feature

import { useState, useEffect } from 'react';
import GetClickOutside from './GetClickOutside';
import MultiInputItem from './MultiInputItem';
import MultiInputItemEdit from './MultiInputItemEdit';
import ChevronUpDown from './ChevronUpDown';
import { useModal } from './ModalProvider';
import './MultiInput.scss';

export default function MultiInput({
  value,
  onChange,
  small,
  className,
  placeholder,
  error,
  defaultNew,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [zIndex, setZIndex] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const { openModal } = useModal();

  useEffect(() => {
    if (isOpen) {
      setZIndex(true);
    } else setTimeout(() => setZIndex(false), 300);
  }, [isOpen]);

  return (
    <div
      className={
        'multi-input' +
        (small ? ' small' : '') +
        (className ? ' ' + className : '')
      }
    >
      <GetClickOutside active={isOpen} onClick={() => setIsOpen(false)}>
        <div
          className={'input-field' + (error ? ' error' : '')}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className={'value' + (value.length > 0 ? '' : ' placeholder')}>
            {value.length > 0 ? value.join(', ') : placeholder}
          </p>
          <ChevronUpDown up={isOpen} />
          <p className={'error-msg' + (error ? ' visible' : '')}>{error}</p>
        </div>
        <div
          className={
            'items' + (isOpen ? ' open' : '') + (zIndex ? ' z-index' : '')
          }
        >
          {value.map((item, index) => (
            <MultiInputItem
              key={index}
              item={item}
              small={small}
              onEdit={(newItem) => {
                onChange(
                  [
                    ...value.slice(0, index),
                    newItem,
                    ...value.slice(index + 1),
                  ],
                  {
                    action: 'edit',
                    index,
                    item: newItem,
                  }
                );
              }}
              onDelete={() => {
                openModal({
                  header: 'Delete field',
                  body: (
                    <p className='font-16'>
                      {'Are you sure you want to delete the '}
                      <span className='font-medium'>{item}</span>
                      {' field with all the games?'}
                    </p>
                  ),
                  buttons: [
                    {
                      text: 'Cancel',
                      className: 'bw',
                    },
                    {
                      text: 'Delete',
                      className: 'danger',
                      onClick: () => {
                        onChange(
                          [...value.slice(0, index), ...value.slice(index + 1)],
                          {
                            action: 'delete',
                            index,
                          }
                        );
                        setIsOpen(true);
                      },
                    },
                  ],
                });
              }}
              onUp={() => {
                if (index === 0) return;
                const result = [...value];
                [result[index - 1], result[index]] = [
                  result[index],
                  result[index - 1],
                ];
                onChange(result, {
                  action: 'up',
                  index,
                });
              }}
              onDown={() => {
                if (index === value.length - 1) return;
                const result = [...value];
                [result[index], result[index + 1]] = [
                  result[index + 1],
                  result[index],
                ];
                onChange(result, {
                  action: 'down',
                  index,
                });
              }}
            />
          ))}
          <div className={'add-wrap small'}>
            <button
              className={'add-btn small bw' + (addMode ? ' add-mode' : '')}
              onClick={() => setAddMode(true)}
            >
              Add
            </button>
            <MultiInputItemEdit
              className='add-edit'
              onClose={() => setAddMode(false)}
              onSave={(newItem) => {
                onChange([...value, newItem], {
                  action: 'add',
                  index: value.length,
                  item: newItem,
                });
                setAddMode(false);
              }}
              small
              show={addMode}
              initValue={defaultNew}
            />
          </div>
        </div>
      </GetClickOutside>
    </div>
  );
}
