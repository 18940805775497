// Text with check or cross marks

import './MarkedText.scss';

export default function MarkedText({ text, mark }) {
  return (
    <div className='marked-text'>
      <p className={'mark' + (mark ? ' green fa-check' : ' red fa-cross')} />
      <p className='text'>{text}</p>
    </div>
  );
}
