// Showing a set of game tables with control elements

import { useState, useMemo, useRef } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import GameListTable from './GameListTable';
import ColorLegend from './ColorLegend';
import CheckBox from './CheckBox';
import GameListPDF from './GameListPDF';
import './GameList.scss';

export default function GameList({
  tournamentName,
  tournamentLocation,
  games,
  fields,
  timeSlots,
  colorLegend,
  startDate,
  endDate,
}) {
  const [refereesFlag, setRefereesFlag] = useState(false);
  const pdfRef = useRef(null);

  const days = useMemo(() => {
    const days = [];
    let day = startDate.clone();
    const lastDay = endDate.clone();

    do {
      days.push(day.clone());
      day.add(1, 'day');
    } while (day.isSameOrBefore(lastDay));

    return days;
  }, [startDate, endDate]);

  const sortedGames = games.sort((a, b) => {
    if (a.start !== b.start) return a.start - b.start;
    return a.field - b.field;
  });

  const referees = [...new Set(games.map((game) => game.referees).flat())];

  const onPrintPDF = useReactToPrint({
    contentRef: pdfRef,
    documentTitle: refereesFlag ? 'Referees' : 'Games',
  });

  return (
    <div className='game-list'>
      <div className='controls'>
        <div className='row'>
          <CheckBox
            label='Referees'
            className='referees-check-box'
            value={refereesFlag}
            onChange={(val) => setRefereesFlag(val)}
          />
          <button className='transparent save-to-pdf-btn' onClick={onPrintPDF}>
            <div className='bg-image wh-32 pdf-icon' />
            Print/Save as PDF
          </button>
        </div>
        <ColorLegend
          colorLegend={Object.fromEntries(
            Object.entries(colorLegend).map(([k, v]) => [k, v + '50'])
          )}
        />
      </div>
      {!refereesFlag &&
        days.map((day, i) => {
          let dayTotal = 0;

          return (
            <div className='day' key={i}>
              <h4 className='header-date'>{day.format('MMMM D, YY')}</h4>
              {fields
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map((field, i) => {
                  const filteredGames = sortedGames
                    .filter((game) => day.isSame(moment(game.date)))
                    .filter((game) => game.field === i);

                  dayTotal += filteredGames.length;

                  return (
                    <div className='field-table' key={i}>
                      <h6 className='header-field'>{field.name}</h6>
                      <GameListTable
                        games={filteredGames}
                        fields={fields}
                        timeSlots={timeSlots}
                        colorLegend={colorLegend}
                        noFieldCol
                      />
                      <h6 className='total-field'>
                        {`Total games on ${field.name}: ${filteredGames.length} games`}
                      </h6>
                    </div>
                  );
                })}
              <h4 className='total-day'>{`Total games on ${day.format(
                'MMMM D'
              )}: ${dayTotal} games`}</h4>
            </div>
          );
        })}
      {refereesFlag &&
        referees.map((referee, i) => {
          let refereeTotal = 0;

          return (
            <div className='referee' key={i}>
              <h4 className='header-referee'>{referee}</h4>
              {days.map((day, i) => {
                const filteredGames = sortedGames
                  .filter((game) => day.isSame(moment(game.date)))
                  .filter((game) => game.referees.includes(referee));

                refereeTotal += filteredGames.length;

                return (
                  <div className='day-table' key={i}>
                    <h6 className='header-day'>{day.format('MMMM D, YY')}</h6>
                    <GameListTable
                      games={filteredGames}
                      fields={fields}
                      timeSlots={timeSlots}
                      colorLegend={colorLegend}
                    />
                    <h6 className='total-day'>
                      {`Total games on ${day.format('MMMM D')}: ${
                        filteredGames.length
                      } games`}
                    </h6>
                  </div>
                );
              })}
              <h4 className='total-referee'>{`Total games of ${referee}: ${refereeTotal} games`}</h4>
            </div>
          );
        })}
      <div ref={pdfRef} className='game-list-pdf-wrap'>
        <GameListPDF
          tournamentName={tournamentName}
          tournamentLocation={tournamentLocation}
          games={sortedGames}
          fields={fields}
          timeSlots={timeSlots}
          colorLegend={colorLegend}
          startDate={startDate}
          endDate={endDate}
          days={days}
          referees={referees}
          refereesFlag={refereesFlag}
        />
      </div>
    </div>
  );
}
