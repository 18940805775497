// Referee details interface

import { useNavigate } from 'react-router-dom';
import CardTemplate from './CardTemplate';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import RefereeCardEdit from './RefereeCardEdit';
import { useFirebase } from './FirebaseContextProvider';
import { refereeUpdate } from '../utils/firebase';
import { UsersRoles } from '../utils/enums';

export default function RefereeCard({ referee }) {
  const navigate = useNavigate();
  const { user } = useFirebase();

  const { id, imgUrl, imgThumbUrl, name, phone, email } = referee;

  const userIsAdmin = user.role === UsersRoles.ADMIN;

  return (
    <CardTemplate
      header='Referee'
      className='referee-card'
      cardBtnText='Back'
      cardBtnOnClick={() => navigate(-1)}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle={name}
      name={name}
      details={
        <PropItemsBlock>
          <PropItem prop='Phone' value={phone} />
          <PropItem prop='e-mail' value={email} />
        </PropItemsBlock>
      }
      editForm={userIsAdmin && <RefereeCardEdit referee={referee} />}
      onDelete={
        userIsAdmin && (async () => await refereeUpdate({ id, active: false }))
      }
      afterDeleteNavPath='/admin#referees'
    >
      <div className='block' />
    </CardTemplate>
  );
}
