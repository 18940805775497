// Team referee

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import RefereeCard from '../components/RefereeCard';
import Spinner from '../components/Spinner';

export default function PageCoach() {
  const referee = useLoaderData();

  return (
    <Layout className='page-referee'>
      {!referee.loading && <RefereeCard referee={referee} />}
      <Spinner show={referee.loading} />
    </Layout>
  );
}
