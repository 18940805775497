// Used with the Field to visualize a game on the field timeline

import { useState, useEffect, useRef, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import GameBlockEdit from './GameBlockEdit';
import GameBlockScore from './GameBlockScore';
import { useFirebase } from './FirebaseContextProvider';
import {
  scheduleStartHour,
  scheduleEndHour,
  scheduleStepMin,
} from '../utils/consts';
import './GameBlock.scss';
import { getTeamDivision } from '../utils/misc';

export const ItemTypes = {
  GAME_BLOCK: 'GAME_BLOCK',
  RESIZE_HANDLE: 'RESIZE_HANDLE',
};

function getTimeSlotIndex(timeStr) {
  const [inputHour, inputMin] = timeStr.split(':').map(Number);
  const totalInputMinutes = inputHour * 60 + inputMin;
  const scheduleStartMinutes = scheduleStartHour * 60;
  const scheduleEndMinutes = scheduleEndHour * 60;

  if (
    totalInputMinutes < scheduleStartMinutes ||
    totalInputMinutes >= scheduleEndMinutes
  )
    return -1; // Out of schedule

  return Math.floor(
    (totalInputMinutes - scheduleStartMinutes) / scheduleStepMin
  );
}

export default function GameBlock({
  id,
  game,
  timeSlotHeight,
  color,
  setBracketsPopup,
  setGame,
}) {
  const { teams, referees } = useFirebase();

  const { start, duration, score1, score2, round, placement } = game;

  const team1 = game.team1
    ? teams.find((team) => team.id === game.team1)
    : game.team1;

  const team2 = game.team2
    ? teams.find((team) => team.id === game.team2)
    : game.team2;

  const gameRefs = referees
    .filter((ref) => game.referees.includes(ref.id))
    .map((ref) => ref.name);

  const [editMode, setEditMode] = useState(false);
  const [scoringMode, setScoringMode] = useState(false);
  const [currentY, setCurrentY] = useState(null);
  const [rectCoords, setRectCoords] = useState({ top: null, bottom: null });
  const contentRef = useRef(null);

  const { top, bottom } = rectCoords;

  const [{ isDragging }, dragRef] = useDrag({
    type: ItemTypes.GAME_BLOCK,
    item: { id: game.id, type: ItemTypes.GAME_BLOCK },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isResizingTop }, dragTopRef] = useDrag({
    type: ItemTypes.RESIZE_HANDLE,
    item: { id: game.id, resizeType: 'start' },
    collect: (monitor) => ({
      isResizingTop: monitor.isDragging(),
    }),
  });

  const [{ isResizingBottom }, dragBottomRef] = useDrag({
    type: ItemTypes.RESIZE_HANDLE,
    item: { id: game.id, resizeType: 'end' },
    collect: (monitor) => ({
      isResizingBottom: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    const handleMouseMove = (event) => setCurrentY(event.pageY);

    const removeListener = () => {
      setCurrentY(null);
      document.removeEventListener('dragover', handleMouseMove);
    };

    if (isResizingTop || isResizingBottom) {
      document.addEventListener('dragover', handleMouseMove);
    } else removeListener();

    return () => removeListener();
  }, [isResizingTop, isResizingBottom]);

  useEffect(() => {
    if (!contentRef.current) return;

    const rect = contentRef.current.getBoundingClientRect();
    setRectCoords({
      top: rect.top + window.scrollY,
      bottom: rect.bottom + window.scrollY,
    });
  }, [start, duration]);

  const deltaY = useMemo(() => {
    if (!currentY) return null;
    if (isResizingTop) return currentY - top;
    if (isResizingBottom) return currentY - bottom;
    return null;
  }, [isResizingTop, isResizingBottom, currentY]);

  return (
    <div
      className={`game-block ${isDragging ? 'dragging' : ''} ${
        isResizingTop || isResizingBottom ? 'resizing' : ''
      }`}
      style={{
        top:
          getTimeSlotIndex(start) * timeSlotHeight +
          (isResizingTop ? deltaY : 0),
        height:
          (duration / scheduleStepMin) * timeSlotHeight +
          (isResizingBottom ? deltaY : 0) -
          (isResizingTop ? deltaY : 0),
      }}
      ref={dragRef}
    >
      <div
        className='content-wrap'
        ref={contentRef}
        style={{ borderColor: color, backgroundColor: `${color}1A` }}
      >
        <div
          className='resize-handle top'
          ref={dragTopRef}
          style={{ backgroundColor: color }}
        />
        <p className='division'>{getTeamDivision(team1)}</p>
        {(team1 || team2) && (
          <p className='text font-medium'>{`${
            team1 !== null ? team1.name : 'TBD'
          } ${
            score1 !== null || score2 !== null ? score1 + ' : ' + score2 : ' - '
          } ${team2 !== null ? team2.name : 'TBD'}`}</p>
        )}
        <p className='referees'>{'Referees: ' + gameRefs.join(', ')}</p>
        {round !== null && placement !== null && (
          <a
            className='font-medium round-game'
            onClick={() => setBracketsPopup({ id: game.id, mode: 'ro' })}
          >{`Playoff: round ${round + 1}, game ${placement + 1}`}</a>
        )}
        <button
          className='score-btn bw small'
          onClick={() => setScoringMode(true)}
        >
          Score
        </button>
        <button className='edit-btn bw small' onClick={() => setEditMode(true)}>
          Edit
        </button>
        <div
          className='resize-handle bottom'
          ref={dragBottomRef}
          style={{ backgroundColor: color }}
        />
      </div>
      <GameBlockScore
        id={id}
        gameId={game.id}
        show={scoringMode}
        onClose={() => setScoringMode(false)}
        setGame={setGame}
        team1={team1.name}
        team2={team2.name}
        score1={score1}
        score2={score2}
      />
      <GameBlockEdit
        show={editMode}
        onClose={() => setEditMode(false)}
        id={id}
        game={game}
        setBracketsPopup={setBracketsPopup}
      />
    </div>
  );
}
