// Using to convert GameList's content to pdf

import moment from 'moment';
import ColorLegend from './ColorLegend';
import GameListTable from './GameListTable';
import { formatDateRange } from '../utils/misc';
import './GameListPDF.scss';

const PageTop = ({
  tournamentName,
  tournamentLocation,
  startDate,
  endDate,
  colorLegend,
  newPage,
}) => (
  <>
    <h4 className={'title' + (newPage ? ' new-page' : '')}>
      {`${tournamentName.replace(
        /\s/g,
        '\u00A0'
      )}, ${tournamentLocation.replace(/\s/g, '\u00A0')}, ${formatDateRange(
        startDate,
        endDate
      ).replace(/\s/g, '\u00A0')}`}
    </h4>
    <ColorLegend
      colorLegend={Object.fromEntries(
        Object.entries(colorLegend).map(([k, v]) => [k, v + '50'])
      )}
    />
  </>
);

export default function GameListPDF({
  tournamentName,
  tournamentLocation,
  games,
  fields,
  timeSlots,
  colorLegend,
  startDate,
  endDate,
  days,
  referees,
  refereesFlag,
}) {
  return (
    <div className='game-list-pdf'>
      {!refereesFlag && (
        <PageTop
          tournamentName={tournamentName}
          tournamentLocation={tournamentLocation}
          startDate={startDate}
          endDate={endDate}
          colorLegend={colorLegend}
        />
      )}
      {!refereesFlag &&
        days.map((day, i) => {
          let dayTotal = 0;

          return (
            <div className={'day' + (i === 0 ? '' : ' new-page')} key={i}>
              <h4 className='header-date'>{day.format('MMMM D, YY')}</h4>
              {fields
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map((field) => {
                  const filteredGames = games
                    .filter((game) => day.isSame(moment(game.date)))
                    .filter((game) => game.field === field.id);

                  dayTotal += filteredGames.length;

                  return (
                    <div className='field-table' key={field.id}>
                      <h6 className='header-field'>{field.name}</h6>
                      <GameListTable
                        games={filteredGames}
                        fields={fields}
                        timeSlots={timeSlots}
                        colorLegend={colorLegend}
                        noFieldCol
                      />
                      <h6 className='total-field'>
                        {`Total games on ${field.name}: ${filteredGames.length} games`}
                      </h6>
                    </div>
                  );
                })}
              <h4 className='total-day'>{`Total games on ${day.format(
                'MMMM D'
              )}: ${dayTotal} games`}</h4>
            </div>
          );
        })}
      {refereesFlag &&
        referees.map((referee, i) => {
          let refereeTotal = 0;

          return (
            <>
              <PageTop
                tournamentName={tournamentName}
                tournamentLocation={tournamentLocation}
                startDate={startDate}
                endDate={endDate}
                colorLegend={colorLegend}
                newPage
              />
              <div className={'referee'} key={i}>
                <h4 className='header-referee'>{referee}</h4>
                {days.map((day, i) => {
                  const filteredGames = games
                    .filter((game) => day.isSame(moment(game.date)))
                    .filter((game) => game.referees.includes(referee));

                  refereeTotal += filteredGames.length;

                  return (
                    <div className='day-table' key={i}>
                      <h6 className='header-day'>{day.format('MMMM D, YY')}</h6>
                      <GameListTable
                        games={filteredGames}
                        fields={fields}
                        timeSlots={timeSlots}
                        colorLegend={colorLegend}
                      />
                      <h6 className='total-day'>
                        {`Total games on ${day.format('MMMM D')}: ${
                          filteredGames.length
                        } games`}
                      </h6>
                    </div>
                  );
                })}
                <h4 className='total-referee'>{`Total games of ${referee}: ${refereeTotal} games`}</h4>
              </div>
            </>
          );
        })}
    </div>
  );
}
