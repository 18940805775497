// Visualising tournament brackets

import ReactFlow, { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import BracketsNode from './BracketsNode';
import BracketsTitle from './BracketsTitle';
import { timeStr } from './GameListTable';
import './Brackets.scss';

const levels = 4;

const roundLabels = [
  'Final',
  'Semifinal',
  'Quarterfinal',
  '1/8 Final',
  '1/16 Final',
  '1/32 Final',
  '1/64 Final',
  '1/128 Final',
];

function generateTreeData({
  maxLevels,
  games,
  setGame,
  fields,
  timeSlots,
  highlight,
  mode,
}) {
  const nodes = [];
  const edges = [];
  let idCounter = 0;

  function getId() {
    return `node-${idCounter++}`;
  }

  function addNode(x, y, level, placement) {
    const id = getId();
    const round = maxLevels - level - 1;
    const game = games.find(
      (game) => game.round === round && game.placement === placement
    );
    const {
      text,
      team1,
      team2,
      score1,
      score2,
      date,
      start,
      duration,
      field,
      referees,
    } = game || {};

    nodes.push({
      id,
      position: { x, y },
      data: {
        placement,
        text,
        team1,
        team2,
        score1,
        score2,
        date,
        time: timeStr(timeSlots, start, duration),
        field: fields.find((f) => f.id === field)?.name || '',
        referees,
        highlight: game && game.id === highlight,
        mode,
        onClick:
          mode === 'set'
            ? () =>
                setGame((games) =>
                  games
                    .map((game) =>
                      game.round === round && game.placement === placement
                        ? { ...game, round: null, placement: null }
                        : game
                    )
                    .map((game) =>
                      game.id === highlight
                        ? { ...game, round, placement }
                        : game
                    )
                )
            : undefined,
      },
      type: 'customNode',
    });
    return id;
  }

  const horizontalSpacing = 320;
  const verticalSpacing = 80;

  const rootId = addNode(0, 0, 0, 0);

  function generateSubtree({
    parentId,
    parentPos,
    parentX,
    parentY,
    currentLevel,
    maxLevel,
    isLeftBranch,
    leftRightBranches,
  }) {
    if (currentLevel >= maxLevel - 1) return;

    const childXTop =
      isLeftBranch || leftRightBranches
        ? parentX - horizontalSpacing
        : parentX + horizontalSpacing;
    const childXBottom =
      !isLeftBranch || leftRightBranches
        ? parentX + horizontalSpacing
        : parentX - horizontalSpacing;
    const childYTop = leftRightBranches
      ? parentY
      : parentY - verticalSpacing * 2 ** (maxLevel - currentLevel - 2);
    const childYBottom = leftRightBranches
      ? parentY
      : parentY + verticalSpacing * 2 ** (maxLevel - currentLevel - 2);

    const topChildPos = parentPos * 2;
    const bottomChildPos = parentPos * 2 + 1;

    const topChildId = addNode(
      childXTop,
      childYTop,
      currentLevel + 1,
      topChildPos
    );
    const bottomChildId = addNode(
      childXBottom,
      childYBottom,
      currentLevel + 1,
      bottomChildPos
    );

    edges.push({
      id: `edge-${parentId}-${topChildId}`,
      source: parentId,
      target: topChildId,
      sourceHandle:
        isLeftBranch || leftRightBranches ? 'left-output' : 'right-output',
      targetHandle:
        isLeftBranch || leftRightBranches ? 'right-input' : 'left-input',
    });

    edges.push({
      id: `edge-${parentId}-${bottomChildId}`,
      source: parentId,
      target: bottomChildId,
      sourceHandle:
        !isLeftBranch || leftRightBranches ? 'right-output' : 'left-output',
      targetHandle:
        !isLeftBranch || leftRightBranches ? 'left-input' : 'right-input',
    });

    generateSubtree({
      parentId: topChildId,
      parentPos: topChildPos,
      parentX: childXTop,
      parentY: childYTop,
      currentLevel: currentLevel + 1,
      maxLevel,
      isLeftBranch: leftRightBranches ? true : isLeftBranch,
    });

    generateSubtree({
      parentId: bottomChildId,
      parentPos: bottomChildPos,
      parentX: childXBottom,
      parentY: childYBottom,
      currentLevel: currentLevel + 1,
      maxLevel,
      isLeftBranch: leftRightBranches ? false : isLeftBranch,
    });
  }

  generateSubtree({
    parentId: rootId,
    parentPos: 0,
    parentX: 0,
    parentY: 0,
    currentLevel: 0,
    maxLevel: maxLevels,
    leftRightBranches: true,
  });

  function generateTitles() {
    const usedLabels = roundLabels.slice(0, maxLevels);
    const centerLabel = usedLabels[0];
    const sideLabels = usedLabels.slice(1);
    const leftSide = [...sideLabels].reverse();
    const finalTitles = [...leftSide, centerLabel, ...sideLabels];

    const minY = Math.min(...nodes.map((node) => node.position.y));
    const y = minY - verticalSpacing;
    const x = [...new Set(nodes.map((node) => node.position.x))].sort(
      (a, b) => a - b
    );

    finalTitles.forEach((title, i) => {
      nodes.push({
        id: `title-${i}`,
        position: { x: x[i], y },
        data: { title },
        type: 'titleNode',
      });
    });
  }

  generateTitles();

  return { nodes, edges };
}

export default function Brackets({
  games,
  setGame,
  fields,
  timeSlots,
  highlight,
  mode = 'ro',
}) {
  const { nodes, edges } = generateTreeData({
    maxLevels: levels,
    games,
    setGame,
    fields,
    timeSlots,
    highlight,
    mode,
  });

  return (
    <div className='brackets'>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={{ customNode: BracketsNode, titleNode: BracketsTitle }}
          defaultEdgeOptions={{ type: 'step' }}
          fitView
        />
      </ReactFlowProvider>
    </div>
  );
}
