import moment from 'moment-timezone';
import { imgThumbPostfix } from './consts';
import { Genders } from './enums';

// Regular expression to check a email
export const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// Date range formater
export function formatDateRange(startDate, endDate) {
  if (startDate.isSame(endDate, 'year')) {
    if (startDate.isSame(endDate, 'month')) {
      if (startDate.isSame(endDate, 'day')) {
        return startDate.format('MMM D, YY');
      } else {
        return `${startDate.format('MMM D')}-${endDate.format('D, YY')}`;
      }
    } else {
      return `${startDate.format('MMM D')} – ${endDate.format('MMM D, YY')}`;
    }
  } else {
    return `${startDate.format('MMM D, YY')} – ${endDate.format('MMM D, YY')}`;
  }
}

// Convert { date, time, timezone } obj to moment obj
export function getMatchMomentUTC({ date, time, timezone }) {
  return moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', timezone);
}

// Convert moment obj to { date, time, timezone } obj
export function getMatchDateTimeTz(momentObj) {
  return {
    date: momentObj.format('YYYY-MM-DD'),
    time: momentObj.format('HH:mm'),
    timezone: momentObj.tz(),
  };
}

// Convert time from 24 to 12-hour format
export function time24To12H(time) {
  return moment(time, 'HH:mm').format('h:mma');
}

// Convert time from 12 to 24-hour format
export function time12To24H(time) {
  const cleaned = time.replace(/\s+/g, '');
  return moment(cleaned, 'h:mma').format('HH:mm');
}

// Convert hours to time in 12-hour format
export function hourTo12H(hour) {
  return moment({ hour }).format('h:mma');
}

// Add thumbnail postfix to filename
export function addThumbnailPostfix(filename) {
  if (!filename) return '';

  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1) return filename + imgThumbPostfix;

  const name = filename.slice(0, dotIndex);
  const extension = filename.slice(dotIndex);

  return `${name}${imgThumbPostfix}${extension}`;
}

// Finds the key corresponding to a given value in an object
export const getKeyByValue = (obj, value) => {
  const entry = Object.entries(obj).find(([_, v]) => v === value);
  return entry ? entry[0] : undefined;
};

// Switch 'Male', 'Female' to 'Boys', 'Girls' and back
export const switchGender = (gender) => {
  switch (gender) {
    case Genders.MALE:
      return 'Boys';
    case Genders.FEMALE:
      return 'Girls';
    case 'Boys':
      return Genders.MALE;
    case 'Girls':
      return Genders.FEMALE;
    default:
      return gender;
  }
};

// Return team's division as a string
export function getTeamDivision(team) {
  return `${team.sport}, ${switchGender(team.gender)}, ${team.age}`;
}

// Capitalizing the first letter of a string
export function capFirstLetter(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Decapitalizing the first letter of a string
export function decapFirstLetter(string) {
  if (!string) return '';
  return string.charAt(0).toLowerCase() + string.slice(1);
}
