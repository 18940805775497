// Using this as a node for tournament brackets

import moment from 'moment';
import { Handle, Position } from 'reactflow';
import './BracketsNode.scss';

export default function BracketsNode({ data }) {
  const {
    date,
    time,
    field,
    text,
    team1,
    team2,
    score1,
    score2,
    referees,
    placement,
    highlight,
    mode,
    onClick,
  } = data || {};

  return (
    <div
      className={
        'brackets-node' +
        (highlight ? ' highlight' : '') +
        (mode ? ' ' + mode : '')
      }
      onClick={onClick}
    >
      <Handle
        type='target'
        position={Position.Left}
        id='left-input'
        style={{ visibility: 'hidden' }}
      />
      <Handle
        type='target'
        position={Position.Right}
        id='right-input'
        style={{ visibility: 'hidden' }}
      />
      <Handle
        type='source'
        position={Position.Left}
        id='left-output'
        style={{ visibility: 'hidden' }}
      />
      <Handle
        type='source'
        position={Position.Right}
        id='right-output'
        style={{ visibility: 'hidden' }}
      />
      {date && (
        <>
          <p>{`${moment(date).format('MMM D, YYYY')}`}</p>
          <p>
            {time}, {field}
          </p>
          {text && <p className='font-medium'>{text}</p>}
          {(team1 || team2) && (
            <p className='text font-medium'>{`${
              team1 !== null ? team1 : 'TBD'
            } ${
              score1 !== null || score2 !== null
                ? score1 + ' : ' + score2
                : ' - '
            } ${team2 !== null ? team2 : 'TBD'}`}</p>
          )}
          <p className='referees'>
            Referees:{' '}
            {referees
              .map((referee) => referee.replace(/ /g, '\u00A0'))
              .join(', ')}
          </p>
        </>
      )}
      {!date && <p>Isn't scheduled</p>}
      <p className='placement'>{'#' + (placement + 1)}</p>
    </div>
  );
}
