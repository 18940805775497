// Using this with MultiInput and MultiInputItem components

import { useState, useEffect } from 'react';
import Input from './Input';
import './MultiInputItemEdit.scss';

export default function MultiInputItemEdit({
  className,
  onClose,
  onSave,
  small,
  initValue,
  show = true,
}) {
  const [newItem, setNewItem] = useState(initValue);

  useEffect(() => {
    if (!show) setTimeout(() => setNewItem(initValue), 300);
  }, [show, initValue]);

  return (
    <div
      className={
        'multi-input-item-edit' +
        (show ? ' show' : '') +
        (className ? ' ' + className : '')
      }
    >
      <Input
        className='input-new-item'
        placeholder='New item'
        small={small}
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
      />
      <button
        className={`bw${small ? ' small' : ''}`}
        onClick={() => onClose()}
      >
        Cancel
      </button>
      <button
        className={small ? ' small' : ''}
        onClick={() => {
          if (newItem) onSave(newItem);
          onClose();
        }}
      >
        Save
      </button>
    </div>
  );
}
