// Edition form for tournament card

import moment from 'moment';
import * as Yup from 'yup';
import { tournamentUpdate, tournamentCreate } from '../utils/firebase';
import Input from './Input';
import Select from './Select';
import CardEditTemplate from './CardEditTemplate';
import InputDate from './InputDate';
import MultiSelect from './MultiSelect';
import { Ages, Sports, Genders } from '../utils/enums';
import { getKeyByValue, getMatchDateTimeTz, switchGender } from '../utils/misc';
import { defaultTimeZone } from '../utils/consts';
import './TournamentCardEdit.scss';

export default function TournamentCardEdit({ tournament }) {
  const {
    id,
    name,
    location,
    startDate,
    endDate,
    sports,
    ageFrom,
    ageTo,
    genders,
    imgUrl,
    imgThumbUrl,
  } = tournament;

  const initialValues = {
    name: name || '',
    location: location || '',
    startDate: startDate?.toDate() || null,
    endDate: endDate?.toDate() || null,
    sports: sports || [],
    ageFrom: ageFrom || null,
    ageTo: ageTo || null,
    genders: genders || [],
  };

  const validationSchema = new Yup.ObjectSchema({
    name: Yup.string().max(100, 'Too long').required('Required'),
    location: Yup.string().max(100, 'Too long').required('Required'),
    sports: Yup.array().min(1, 'Required'),
    genders: Yup.array().min(1, 'Required'),
    ageFrom: Yup.string().required('Required'),
    ageTo: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required'),
  });

  const dateConvert = (obj) => {
    const { startDate, endDate } = obj;
    if (!startDate && !endDate) return obj;

    const tmpObj = { ...obj };
    if (startDate)
      tmpObj.startDate = getMatchDateTimeTz(moment(startDate)).date;
    if (endDate) tmpObj.endDate = getMatchDateTimeTz(moment(endDate)).date;
    tmpObj.timezone = defaultTimeZone;

    return tmpObj;
  };

  return (
    <CardEditTemplate
      className='tournament-card-edit'
      id={id}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgPath={`tournaments/${id}/`}
      formConfig={{
        initialValues,
        validationSchema,
      }}
      create={(args) => tournamentCreate(dateConvert(args))}
      update={(args) => tournamentUpdate(dateConvert(args))}
      afterCreateNavPath='/admin#tournaments'
    >
      {(formik) => (
        <>
          <Input
            name='name'
            placeholder='Name'
            {...formik.getFieldProps('name')}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
          <Input
            name='location'
            placeholder='Location'
            {...formik.getFieldProps('location')}
            error={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : null
            }
          />
          <div className='sports-dates'>
            <MultiSelect
              options={Object.values(Sports)}
              value={formik.values.sports}
              onChange={async (newSports) => {
                await formik.setFieldValue('sports', newSports);
                await formik.setTouched({ ...formik.touched, sports: true });
              }}
              className='sports'
              placeholder='Sports'
              error={
                formik.touched.sports && formik.errors.sports
                  ? formik.errors.sports
                  : null
              }
            />
            <div className='dates'>
              <InputDate
                value={formik.values.startDate}
                onChange={async (newDate) => {
                  const endDate = formik.values.endDate;
                  await formik.setFieldValue('startDate', newDate);
                  await formik.setTouched({
                    ...formik.touched,
                    startDate: true,
                  });
                  if (endDate && newDate > endDate)
                    formik.setFieldValue('endDate', newDate);
                }}
                className='begin-date'
                minDate={new Date()}
                placeholder='Begin at'
                error={
                  formik.touched.startDate && formik.errors.startDate
                    ? formik.errors.startDate
                    : null
                }
              />
              <InputDate
                value={formik.values.endDate}
                onChange={async (newDate) => {
                  const startDate = formik.values.startDate;
                  await formik.setFieldValue('endDate', newDate);
                  await formik.setTouched({ ...formik.touched, endDate: true });
                  if (startDate && startDate > newDate)
                    formik.setFieldValue('startDate', newDate);
                }}
                className='end-date'
                minDate={new Date()}
                placeholder='End at'
                error={
                  formik.touched.endDate && formik.errors.endDate
                    ? formik.errors.endDate
                    : null
                }
              />
            </div>
          </div>
          <div className='division'>
            <MultiSelect
              options={Object.values(Genders).map((gender) =>
                switchGender(gender)
              )}
              value={formik.values.genders.map((gender) =>
                switchGender(gender)
              )}
              onChange={async (newTypes) => {
                await formik.setFieldValue(
                  'genders',
                  newTypes.map((gender) => switchGender(gender))
                );
                await formik.setTouched({ ...formik.touched, genders: true });
              }}
              className='genders'
              placeholder='Genders'
              error={
                formik.touched.genders && formik.errors.genders
                  ? formik.errors.genders
                  : null
              }
            />
            <div className='ages'>
              <Select
                options={Object.values(Ages)}
                value={formik.values.ageFrom}
                onChange={async (val) => {
                  const ageFrom = val;
                  const ageFromKey = getKeyByValue(Ages, ageFrom);
                  const ageTo = formik.values.ageTo;
                  const ageToKey = getKeyByValue(Ages, ageTo);
                  await formik.setFieldValue('ageFrom', ageFrom);
                  await formik.setTouched({ ...formik.touched, ageFrom: true });
                  if (ageTo && +ageFromKey > +ageToKey)
                    formik.setFieldValue('ageTo', ageFrom);
                }}
                placeholder='Age from'
                className='age-from'
                error={
                  formik.touched.ageFrom && formik.errors.ageFrom
                    ? formik.errors.ageFrom
                    : null
                }
              />
              <Select
                options={Object.values(Ages)}
                value={formik.values.ageTo}
                onChange={async (val) => {
                  const ageTo = val;
                  const ageToKey = getKeyByValue(Ages, ageTo);
                  const ageFrom = formik.values.ageFrom;
                  const ageFromKey = getKeyByValue(Ages, ageFrom);
                  await formik.setFieldValue('ageTo', ageTo);
                  await formik.setTouched({ ...formik.touched, ageTo: true });
                  if (ageFrom && +ageFromKey > +ageToKey)
                    formik.setFieldValue('ageFrom', ageTo);
                }}
                placeholder='Age to'
                className='age-to'
                error={
                  formik.touched.ageTo && formik.errors.ageTo
                    ? formik.errors.ageTo
                    : null
                }
              />
            </div>
          </div>
        </>
      )}
    </CardEditTemplate>
  );
}
